@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        font-family: 'Inter', sans-serif;
    }
    ul, ol {
        list-style: revert;
      }    
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.react-select__control {
    color: '#3A434B';
}

.react-select__indicators {
    outline: 0;
}

.percentage-bar:not(:last-child) {
    @apply mb-3
}
